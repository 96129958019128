<template>
  <div class="list-wrapper">
    <div class="form-container">
      <form @submit.prevent="login">
        <label for="username"> Username: </label>
        <input v-model="username" type="username" name="username" value />

        <label for="password"> Password: </label>
        <input v-model="password" type="password" name="password" value />

        <button type="submit" name="button">Login</button>

        <p>{{ error }}</p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      error: null,
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({
            name: "MyDetails",
          });
        })
        .catch((err) => {
          console.log(err);
          this.error = err;
        });
    },
  },
};
</script>

<style scoped></style>
