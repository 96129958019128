<template>
  <h2>Corrugation</h2>
  <div class="wrapper">
    <p>Leading manufacturer of corrugated boxes in Karnataka</p>

    <div class="container">
      <div class="card">High quality Corrugated Boxes</div>
    </div>

    <div class="wrapper">
      <div id="box">
        <div class="top"></div>
        <div>
          <span></span>
          <span>
            <i class="tape"></i>
          </span>
          <span></span>
          <span>
            <i class="tape"></i>
          </span>
        </div>
      </div>
    </div>

    <p>Large variety of quality packaging boxes under one roof</p>
    <div class="container">
      <div class="card">E Flute</div>
      <div class="card">F Flute</div>
      <div class="card">Narrow Flute</div>
    </div>
  </div>

  <div class="wrapper">
    <p>Production capacities</p>
    <div class="container">
      <div class="card">3 Ply</div>
      <div class="card">5 Ply</div>
      <div class="card">7 Ply</div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /*min-height: 100vh;*/
  flex-direction: column;

  padding: 2em;
}

.wrapper p {
  max-width: 900px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex-wrap: wrap;
  padding: 2.5em 0;
}

.container .card {
  position: relative;
  font-size: 1.3em;
  font-weight: 300;
  color: #777;

  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5),
    5px 5px 5px rgba(0, 0, 0, 0.05), -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  margin: 2em;
  padding: 3em;
}

#box {
  margin: 2em;
  position: relative;
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(330deg);
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotateX(-30deg) rotateY(360deg);
  }
  100% {
    transform: rotateX(-30deg) rotateY(0deg);
  }
}

/*drop shadow*/
#box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background: rgba(0, 0, 0, 0.1);
  transform: rotateX(90deg) translate3d(0, 0, -230px);
  filter: blur(15px);
}

#box div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

#box div span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

#box div span:nth-child(1) {
  transform: rotateY(0deg) translate3d(0, 0, 150px);
  background: #d8aa77;
}

#box div span:nth-child(1)::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background: url(../assets/images/amazon.png);
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

#box div span:nth-child(2) {
  transform: rotateY(90deg) translate3d(0, 0, 150px);
  background: #ca9864;
}
#box div span:nth-child(3) {
  transform: rotateY(180deg) translate3d(0, 0, 150px);
  background: #d8aa77;
}

#box div span:nth-child(3)::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background: url(../assets/images/arrow.png);
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

#box div span:nth-child(4) {
  transform: rotateY(270deg) translate3d(0, 0, 150px);
  background: #ca9864;
}

#box .top {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background: #e8bb84;
  display: flex;
  align-items: center;
  transform: rotateX(90deg) translate3d(0, 0, 150px);
}

#box .top::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 60px;
  background: #111 url(../assets/images/tape.jpg);
  background-size: 85px;
}

.tape {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 120px;
  height: 60px;
  background: #111 url(../assets/images/tape.jpg);
  background-size: 85px;
  background-position-x: 45px;
  transform: translateX(-50%) rotate(90deg);
}

span:nth-child(4) .tape {
  transform: translateX(-50%) rotate(270deg);
  background-position-x: 30px;
}

@media (max-width: 600px) {
  #box {
    width: 200px;
    height: 200px;
  }

  #box::before {
    width: 200px;
    height: 200px;
  }

  #box .top {
    width: 200px;
    height: 200px;
    transform: rotateX(90deg) translate3d(0, 0, 100px);
  }

  #box div span:nth-child(1) {
    transform: rotateY(0deg) translate3d(0, 0, 100px);
  }

  #box div span:nth-child(1)::before {
    width: 100px;
    height: 100px;
    background-size: 100px;
  }

  #box div span:nth-child(2) {
    transform: rotateY(90deg) translate3d(0, 0, 100px);
  }

  #box div span:nth-child(3) {
    transform: rotateY(180deg) translate3d(0, 0, 100px);
  }

  #box div span:nth-child(3)::before {
    width: 100px;
    height: 100px;
    background-size: 100px;
  }

  #box div span:nth-child(4) {
    transform: rotateY(270deg) translate3d(0, 0, 100px);
  }

  #box .top::before {
    height: 40px;
    background-size: 60px;
  }

  .tape {
    width: 100px;
    height: 40px;
    background-size: 60px;
  }

  span:nth-child(4) .tape {
    background-position-x: 30px;
    transform: translateX(-50%) rotate(270deg);
  }
}
</style>
