<template>
  <h1>Templates</h1>
  <div class="search-box">
    <div class="search-wrapper">
      <input type="text" v-model="search" placeholder="Search template.." />
      <label>Search template:</label>
    </div>
  </div>
  <div class="templates">
    <TemplateCard
      v-for="template in templates"
      :key="template.id"
      :template="template"
    />
  </div>
</template>

<script>
import TemplateCard from "@/components/TemplateCard.vue";
export default {
  data() {
    return {
      search: "",
    };
  },
  components: {
    TemplateCard,
  },
  created() {
    /*this.$store.dispatch("fetchTemplates").catch((error) => {
      if (error.response && error.response.status == 400) {
        this.$router.push({
          name: "400Error",
          params: { resource: "user" },
        });
      } else if (error.response && error.response.status == 401) {
        this.$router.push({
          name: "LoginGoogle",
        });
      } else if (error) {
        this.$router.push({
          name: "ErrorDisplay",
          params: { error: error },
        });
      } else {
        this.$router.push({
          name: "NetworkError",
          params: { error: error },
        });
      }
    });*/
  },
  computed: {
    templates() {
      return this.$store.state.templates.filter((template) => {
        return template.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.search-wrapper {
  position: relative;
  /*max-width: 720px;*/
  justify-content: center;
}

.search-wrapper label {
  position: absolute;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  top: 8px;
  left: 12px;
  z-index: -1;
  transition: 0.15s all ease-in-out;
}

.search-wrapper input {
  /*padding: 4px 12px;*/
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: 0.15s all ease-in-out;
  background: white;
}

.search-wrapper input:focus {
  outline: none;
  transform: scale(1.05);
}

.search-wrapper input:focus + label {
  font-size: 10px;
  transform: translateY(-24px) translateX(-12px);
}

.search-wrapper input::-webkit-input-placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 100;
}

.templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/*
@media (max-width: 600px) {
  .search-wrapper {
    max-width: 420px;
  }
}*/
</style>
