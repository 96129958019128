<template>
  <div>
    <!-- input
      type="file"
      name="uploadfile"
      id="imageInput"
      style="display: none"
    />
    <label for="imageInput">
      <strong>CLICK HERE TO SELECT BACKGROUND IMAGE</strong>
    </label -->

    <input
      type="file"
      @change="checkAndDrawPicture"
      ref="fileInput"
      name="file"
      style="display: none"
      accept="image/*"
    />
    <button @click="$refs.fileInput.click()">Pick Image</button>

    <canvas
      ref="canvas"
      id="canvas"
      :width="canvasWidth"
      :height="canvasHeight"
    />
    <p>
      <input
        type="text"
        v-model="text"
        placeholder="write your message here"
        v-on:keyup.enter="drawText"
      />
    </p>
    <div class="fontOptions">
      <div class="option">
        <label>Font Size: </label>
        <!-- input v-model="fontSize" type="number" /-->
        <select v-model="fontSize">
          <option
            v-for="option in fontSizes"
            :key="option"
            :value="option"
            :selected="option === fontSize"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="option">
        <label>Font Colour: </label>
        <input v-model="fontColour" type="color" />
      </div>
      <div class="option">
        <label>Font: </label>
        <select v-model="font">
          <option
            v-for="option in fonts"
            :key="option"
            :value="option"
            :selected="option === font"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-container">
      <button @click="resetCanvas">Reset</button>
      <button @click="onUpload">Upload</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { nextTick } from "vue";

export default {
  data() {
    return {
      text: "",
      canvasWidth: 600,
      canvasHeight: 400,
      context: null,
      font: "Avenir",
      fonts: ["Avenir", "Serif", "Helvetica"],
      fontSize: 40,
      fontSizes: [16, 18, 20, 24, 28, 32, 40, 48, 56, 64],
      fontColour: "#333333",
      selectedFile: null,
      image: null,
      isSuccess: false,
      errors: null,
    };
  },
  mounted() {
    this.context = this.$refs.canvas.getContext("2d");
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({
            name: "MyDetails",
          });
        })
        .catch((err) => {
          console.log(err);
          this.error = err.response.data.error;
        });
    },
    resetCanvas() {
      this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    },
    drawText() {
      this.context.font = "normal " + this.fontSize + "px " + this.font;
      this.context.fillStyle = this.fontColour;
      this.context.textAlign = "center"; //textAlign - center, left, right, end, start
      this.context.textBaseline = "middle"; //textBaseline - top, hanging, middle, bottom,ideographic, alphabetic
      this.context.direction = "ltr"; //direction - ltr, rtl, inherit
      this.context.fillText(
        this.text,
        this.canvasWidth / 2,
        this.canvasHeight / 2
      );
    },
    checkAndDrawPicture(event) {
      if (event.target.files) {
        this.selectedFile = event.target.files[0]; //here we get the image file
        var reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onloadend = (event) => {
          this.drawPicture(event.target.result);
        };
      }
    },
    drawPicture(imageSource) {
      this.image = new Image(); // Creates image object
      this.image.src = imageSource; // Assigns converted image to image object
      this.image.onload = () => {
        this.canvasWidth = this.image.width; // Assigns image's width to canvas
        this.canvasHeight = this.image.height; // Assigns image's height to canvas

        //Protect from redrawing and therefore canvas clearing by using nextTick
        nextTick(() => {
          try {
            this.context.drawImage(this.image, 0, 0); // Draws the image on canvas with x and y values
          } catch (e) {
            console.log(`error while changing image: ${e}`);
          }
          this.drawText();
        });
      };
    },
    onUpload() {
      console.log("send file to server using axios");
      this.$refs.canvas.toBlob((blob) => {
        const fd = new FormData();
        fd.append("picture", blob);
        axios
          .post("http://localhost:8081/designs/image", fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Progress: " +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
          })
          .then((response) => {
            console.log(response);
            this.isSuccess = true;
          })
          .catch((response) => {
            if (response.status === 422) {
              this.errors = response.data.errors;
            }
          })
          .finally(() => (this.isLoading = false));
      });
    },
  },
};
</script>

<style scoped>
#canvas {
  border: 1px solid #999;
  margin: 1rem auto;
  display: block;
}

p {
  margin: 1rem auto;
  text-align: center;
}

input {
  width: 600px;
  /*font-size: 2rem;*/
  padding: 0.2rem 1rem;
  box-sizing: border-box;
}

.option {
  margin: 1em;
}
</style>
