import axios from "axios";

const API_URL = "https://sales.winsights.in/api";

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  setToken(token) {
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + token;
  },
  getClient() {
    return apiClient;
  },
  register(credentials) {
    var basicAuth =
      "Basic " + btoa(credentials.username + ":" + credentials.password);
    return axios.post(
      API_URL + "/api/users/register",
      {},
      { headers: { Authorization: +basicAuth } }
    );
  },
  login(credentials) {
    const token = Buffer.from(
      `${credentials.username}:${credentials.password}`,
      "utf8"
    ).toString("base64");
    return apiClient.post(
      "/users/login",
      {},
      { headers: { Authorization: `Basic ${token}` } }
    );
  },
  getTemplates() {
    return apiClient.get("/templates");
  },
  getTemplate(id) {
    return apiClient.get("/templates/" + id);
  },
};
