<template>
  <div class="list-wrapper"></div>
</template>

<script>
export default {
  props: ["id"],
  computed: {
    token() {
      return this.$route.query.token;
    },
  },
  created() {
    this.$store
      .dispatch("authorise", {
        token: this.token,
        id: this.id,
      })
      .then(() => {
        this.$router.replace({
          name: "MyDetails",
        });
      });
  },
};
</script>

<style scoped></style>
