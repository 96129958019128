import { createRouter, createWebHistory } from "vue-router";

import Home from "@/views/Home.vue";
import Corrugation from "@/views/Corrugation.vue";
import Printing from "@/views/Printing.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";

import Register from "@/views/Register.vue";
import Design from "@/views/Design.vue";
import TemplateList from "@/views/template/List.vue";
import Template from "@/views/template/Details.vue";
import Cart from "@/views/Cart.vue";

import LoginRedirect from "@/views/login/Redirect.vue";
import LoginGoogle from "@/views/login/Google.vue";
import LoginBasic from "@/views/login/Basic.vue";
import MyDetails from "@/views/my/Details.vue";

import ErrorDisplay from "@/views/error/ErrorDisplay.vue";
import ClientError from "@/views/error/ClientError.vue";
import ForbiddenError from "@/views/error/ForbiddenError.vue";
import NotFound from "@/views/error/NotFound.vue";
import NetworkError from "@/views/error/NetworkError.vue";

import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/corrugation",
    name: "Corrugation",
    component: Corrugation,
  },
  {
    path: "/printing",
    name: "Printing",
    component: Printing,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/design",
    name: "Design",
    component: Design,
  },
  {
    path: "/template",
    name: "TemplateList",
    component: TemplateList,
  },
  {
    path: "/template/:id",
    name: "Template",
    props: true,
    component: Template,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/auth/:id",
    name: "auth",
    props: true,
    component: LoginRedirect,
  },
  {
    path: "/login",
    name: "login",
    component: LoginBasic,
  },
  {
    path: "/login/google",
    name: "LoginGoogle",
    component: LoginGoogle,
  },
  {
    path: "/my",
    name: "MyDetails",
    component: MyDetails,
    meta: { requiresAuth: true },
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/400/:resource",
    name: "400Error",
    component: ClientError,
    props: true,
  },
  {
    path: "/403/",
    name: "403Error",
    component: ForbiddenError,
    props: true,
  },
  {
    path: "/404/:resource",
    name: "404Resource",
    component: NotFound,
    props: true,
  },
  {
    path: "/network-error",
    name: "NetworkError",
    component: NetworkError,
  },
  {
    path: "/error/:error",
    name: "ErrorDisplay",
    props: true,
    component: ErrorDisplay,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  store.state.mobileNav = false;

  const authenticated = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth) && !authenticated) {
    next("/login/google");
  } else {
    next();
  }
});

export default router;
