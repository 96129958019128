<template>
  <section class="introduction">
    <div class="detail glass">
      Experience The Finest in Printing and Packaging Products
    </div>
    <button class="dark-button">Visit Store</button>
  </section>

  <section class="services">
    <!-- h1>Services</h1 -->
    <div class="services-container">
      <div class="container">
        <div class="card">
          <div class="box">
            <div class="content">
              <h2>Print</h2>
              <h3>Offset Printing</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga at
                esse delectus! Pariatur quod amet natus. Accusantium doloribus,
                aliquam hic commodi alias ex culpa corporis eligendi labore
                corrupti quasi fuga.
              </p>
              <router-link :to="{ name: 'Printing' }">Details</router-link>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="box">
            <div class="content">
              <h2>Box</h2>
              <h3>Corrugation</h3>
              <p>
                E Flute, F Flute and Narrow Flute Corrugated Boxes in 3 Ply, 5
                Ply and 7 Ply.
              </p>
              <router-link :to="{ name: 'Corrugation' }">Details</router-link>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="box">
            <div class="content">
              <h2>Pack</h2>
              <h3>Flexible Packaging</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga at
                esse delectus! Pariatur quod amet natus. Accusantium doloribus,
                aliquam hic commodi alias ex culpa corporis eligendi labore
                corrupti quasi fuga.
              </p>
              <a href="#">Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- section>
    <h1>Products</h1>
    <div class="store">
      <div class="store-container">
        <div class="store-box">
          <div class="icon" style="--i: #4eb7ff">
            <img src="@/assets/icons/bag.svg" alt="" class />
          </div>
          <div class="content">
            <h2>Carry Bag</h2>
            <p>Kraft &amp; Duplex paper carry bags</p>
          </div>
        </div>
        <div class="store-box">
          <div class="icon" style="--i: #fd6494">
            <img src="@/assets/icons/cube.svg" alt="" class />
          </div>
          <div class="content">
            <h2>Corrugated Box</h2>
            <p>Duplex board boxes with inhouse offset printings</p>
          </div>
        </div>
        <div class="store-box">
          <div class="icon" style="--i: #43f390">
            <img src="@/assets/icons/tags.svg" alt="" class />
          </div>
          <div class="content">
            <h2>Tags</h2>
            <p>Aluminium &amp; Plastic foil printing</p>
          </div>
        </div>
        <div class="store-box">
          <div class="icon" style="--i: #ffb508">
            <img src="@/assets/icons/box.svg" alt="" class />
          </div>
          <div class="content">
            <h2>Diecut Box</h2>
            <p>E &amp; F fluted corrugated diecut boxes</p>
          </div>
        </div>
        <div class="store-box">
          <div class="icon" style="--i: #37ba82">
            <img src="@/assets/icons/box-square.svg" alt="" class />
          </div>
          <div class="content">
            <h2>Packing Boxes</h2>
            <p>Crash Lock Bottom Boxes</p>
          </div>
        </div>
        <div class="store-box">
          <div class="icon" style="--i: #cd57ff">
            <img src="@/assets/icons/bag.svg" alt="" class />
          </div>
          <div class="content">
            <h2>Medicine Boxes</h2>
            <p>Pharma Packaging in FDA Approved Board</p>
          </div>
        </div>
      </div>
    </div>
  </section-->

  <section class="custom">
    <div class="detail glass">Custom Services To Match Your Needs</div>
    <!-- button class="dark-button">Get in Touch</button -->
  </section>

  <Printing />

  <section class="speciality">
    <div class="detail glass">Precision Printing</div>
    <button class="dark-button">Details</button>
  </section>

  <section>
    <h1>Multi Colour Offset Printing</h1>
    <div class="layered">
      <div class="layered-container">
        <img src="@/assets/images/parachute.webp" class="center" />
        <img src="@/assets/images/parachute.webp" class="center" />
        <img src="@/assets/images/parachute.webp" class="center" />
        <img src="@/assets/images/parachute.webp" class="center" />
      </div>
    </div>
  </section>

  <section class="printing-intro">
    <div class="detail glass">Special Prints</div>
  </section>

  <!--section class="printing">
    <h1>Specialized Printing</h1>
    <div class="printing-wrapper">
      <div class="printing-container">
        <div class="printing-card" style="--i: -1">
          <div class="printing-content" style="--j: 1">
            <h2>UV coating</h2>
            <p>
              Special coating for a high gloss surface finish with gloss, matte,
              raised and sandpaper finish in full page or spot format. Spot UV
              coating for highlighting a particular area to increase the depth
              of colors or to draw attention
            </p>
          </div>
        </div>
        <div class="printing-card" style="--i: 0">
          <div class="printing-content" style="--j: 2">
            <h2>Lamination &amp; packing</h2>
            <p>
              Lamination protects the richness of colors from fading or
              discolouring and white surfaces from soiling through repeated
              handling and is available in gloss, matt finish to suit the
              special needs of each particular job
            </p>
          </div>
        </div>
        <div class="printing-card" style="--i: 1">
          <div class="printing-content" style="--j: 3">
            <h2>Flexible Packaging</h2>
            <p>
              Aluminium foil, Polyester, BOPP and Poly (HM, LDPE and PP)
              laminate are available
            </p>
          </div>
        </div>
      </div>
    </div>
  </section-->

  <Corrugation />
</template>

<script>
import Printing from "@/components/Printing";
import Corrugation from "@/components/Corrugation";

export default {
  components: { Printing, Corrugation },
  data() {
    return {
      username: "",
      password: "",
      error: null,
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({
            name: "MyDetails",
          });
        })
        .catch((err) => {
          console.log(err);
          this.error = err.response.data.error;
        });
    },
  },
};
</script>

<style scoped>
/* Introduction Section */
.introduction {
  display: grid;
  place-items: center;
  background-image: url(../assets/images/printing-machine.webp);
  background-size: cover;
  height: 80vh;
  font-family: "brandon";
  font-size: 2.5em;
  text-transform: uppercase;
}

.detail {
  max-width: 700px;
  padding: 0.5em;
  margin: 0.5em;
}

.glass {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  border-radius: 10px;
}

/*Store*/
.store {
  display: flex;
  justify-content: center;
  align-items: center;
  /*min-height: 100vh;*/
  background: #fff /*#3f3752*/;

  margin: 1em;
}

.store-container {
  position: relative;
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.store-container .store-box {
  position: relative;
  width: 350px;
  height: 280px;
  background: #51446e;
  border-radius: 20px;
  overflow: hidden;
}

.store-container .store-box .icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--i);
  z-index: 2;
  transition: 0.5s;
  transition-delay: 0.25s;
}

.store-container .store-box:hover .icon {
  top: 30px;
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: 0;
}

.store-container .store-box .content {
  position: relative;
  padding: 20px;
  color: #fff;
  text-align: center;
  margin-top: 100px;
  z-index: 1;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0s;
}

.store-container .store-box:hover .content {
  transform: scale(1);
  transition-delay: 0.25s;
}

.store-container .store-box .content h2 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}

.store-container .store-box .content p {
  font-weight: lighter;
  line-height: 1.5em;
}

/* Custom Section */
.custom {
  display: grid;
  place-items: center;
  background-image: url(../assets/images/fonts-scrambled.webp);
  background-size: cover;
  height: 80vh;
  font-family: "brandon";
  font-size: 2.5em;
  text-transform: uppercase;
}

/* Services Section*/
.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /*min-height: 100vh;*/
  /*margin: 0px;*/
  color: #282828;

  overflow: hidden;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex-wrap: wrap;
  padding: 2em 0;
}

.container .card {
  position: relative;
  width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5),
    5px 5px 5px rgba(0, 0, 0, 0.05), -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  margin: 2em;
}

.container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.container .card:hover .box {
  transform: translateY(-50px);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #b95ce4, #4f29cd);
}

.container .card .box .content {
  padding: 20px;
  text-align: center;
}

.container .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8em;
  color: rgba(0, 0, 0, 0.02);
  transition: 0.5s;
  pointer-events: none;
}

.container .card:hover .box .content h2 {
  color: rgba(0, 0, 0, 0.05);
}

.container .card .box .content h3 {
  z-index: 1;
  transition: 0.5s;
}

.container .card .box .content p {
  font-size: 1em;
  font-weight: 300;
  color: #777;
  z-index: 1;
  transition: 0.5s;
}

.container .card:hover .box .content h3,
.container .card:hover .box .content p {
  color: #fff;
}

.container .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: #03a9f4;
  margin-top: 15px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.container .card:hover .box .content a {
  background: #03a9f4;
}

/* Speciality section */
.speciality {
  display: grid;
  place-items: center;
  background-image: url(../assets/images/pantone.webp);
  background-size: cover;
  height: 80vh;
  font-family: "brandon";
  font-size: 2.5em;
  text-transform: uppercase;
}

/*Layered view explosion*/
.layered {
  /*margin: 0;
  padding: 0;*/
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: -300px;
}
.layered-container {
  position: relative;
  width: 250px;
  height: 415px;
  background: rgba(0, 0, 0, 0, 0.1);
  transform: rotate(-30deg) skew(25deg);
  transition: 0.5s;

  margin: 10em;
  padding: 4em;
}
.layered-container img {
  position: absolute;
  width: 100%;
  transition: 0.5s;
}

.layered-container:hover img:nth-child(1) {
  transform: translate(120px, -120px);
  opacity: 1;
}
.layered-container:hover img:nth-child(4) {
  transform: translate(120px, -120px);
  opacity: 0.8;
}
.layered-container:hover img:nth-child(3) {
  transform: translate(90px, -90px);
  opacity: 0.6;
}
.layered-container:hover img:nth-child(2) {
  transform: translate(60px, -60px);
  opacity: 0.4;
}

/* Printing Intro section */
.printing-intro {
  display: grid;
  place-items: center;
  background-image: url(../assets/images/paper-bundles.webp);
  background-size: cover;
  height: 80vh;
  font-family: "brandon";
  font-size: 2em;
  text-transform: uppercase;
  margin-top: 1em;
}

/*  Printing Section */
.printing-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  padding: 1em;
}

.printing-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: #30bfa5;
  clip-path: circle(250px at center);*/
  transition: 0.5s;
  z-index: 1;
}

.printing-container {
  position: relative;
  width: 1100px;
  height: 400px;
}

.printing-container .printing-card {
  position: absolute;
  top: calc(50% - 200px);
  left: 50%;
  width: 300px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  transform: translateX(-50%) translateY(calc(50px * var(--i))) rotate(40deg)
    skew(-20deg, -10deg) scale(0.6);
  box-shadow: 25px 25px 100px rgba(0, 0, 0, 0.2);
  z-index: calc(-1 * var(--i));
  transition: 0.5s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.printing-container:hover .printing-card {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  opacity: 1;
  transform: translateX(calc(-50% + calc(350px * var(--i)))) translateY(-50%)
    rotate(0deg) skew(0deg, 0deg) scale(1);
}

.printing-container .printing-card:nth-child(2) {
  opacity: 0.8;
}

.printing-container .printing-card:nth-child(3) {
  opacity: 0.6;
  z-index: 0;
}

.printing-container .printing-card .printing-content {
  position: relative;
  padding: 25px;
  color: #333;
  opacity: 0;
  transition: 0.5s;
  transform: translateY(50px);
}

.printing-container:hover .printing-card .printing-content {
  opacity: 1;
  transition-delay: calc(0.2s * var(--j));
  transform: translateY(0px);
}

@media (max-width: 600px) {
  /*Introduction mobile*/
  .introduction {
    font-size: 2em;
    background-image: url(../assets/images/parachute.webp);
  }

  /*Speciality mobile*/
  .speciality {
    font-size: 2em;
    background-image: url(../assets/images/colour-grading.webp);
  }

  /*Store mobile */

  .store-container .store-box .icon {
    top: 30px;
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    transition: 0;
  }

  .store-container .store-box .content {
    transform: scale(1);
  }

  /*Layered mobile*/

  .layered {
    overflow: hidden;
    overflow-x: hidden;
    height: 100vh;
  }

  .layered-container {
    margin: auto;
    padding: auto;
  }

  .layered-container img:nth-child(1) {
    transform: translate(120px, -120px);
    opacity: 1;
  }
  .layered-container img:nth-child(4) {
    transform: translate(120px, -120px);
    opacity: 0.8;
  }
  .layered-container img:nth-child(3) {
    transform: translate(90px, -90px);
    opacity: 0.6;
  }
  .layered-container img:nth-child(2) {
    transform: translate(60px, -60px);
    opacity: 0.4;
  }

  /* Printing Section */
  .printing-wrapper::before {
    clip-path: circle(0px at center);
  }

  .printing-container {
    width: 100%;
    height: initial;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .printing-container .printing-card {
    position: relative;
    top: initial;
    left: initial;
    transform: initial;
    margin: 20px;
    opacity: 1 !important;
  }

  .printing-container:hover,
  .printing-container:hover .printing-card {
    position: relative;
    top: initial;
    left: initial;
    transform: initial;
  }

  .printing-container .printing-card .printing-content {
    transform: initial;
    opacity: 1;
  }
}
</style>
