<template>
  <footer>
    <div id="footer-main">Micro Print and Pack</div>

    <div id="footer-detail">
      No.7&amp;8, 3rd floor Sangeetha Market, Chickpet, Bangalore 560053
    </div>

    <div id="footer-links">
      <a
        href="https://www.facebook.com/"
        target="_blank"
        id="facebook"
        class="social-media"
      >
        <img alt="facebook icon" src="@/assets/icons/facebook.svg" />
      </a>

      <a
        href="https://api.whatsapp.com/send?phone=+91XXXXXXXXXX"
        target="_blank"
        id="whatsapp"
        class="social-media"
      >
        <img alt="whatsapp icon" src="@/assets/icons/whatsapp.svg" />
      </a>
    </div>

    <div id="footer-copyright">©2021 Micro Print and Pack Pvt Ltd</div>
  </footer>
</template>

<script></script>

<style scoped>
.social-media img {
  margin: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  object-fit: cover;
}

#facebook:hover > img,
#facebook:active > img {
  filter: invert(37%) sepia(71%) saturate(4108%) hue-rotate(204deg)
    brightness(99%) contrast(92%);
}

#whatsapp:hover > img,
#whatsapp:active > img {
  filter: invert(89%) sepia(78%) saturate(1439%) hue-rotate(64deg)
    brightness(87%) contrast(90%);
}

footer {
  text-align: center;
  font-size: 1em;
  font-weight: normal;
  font-family: "avenir";
  font-kerning: auto;
}

footer #footer-main {
  margin: 0 auto;
  text-align: center;
  font-family: times;
  font-size: 1.4em;

  margin-bottom: 1em;
  padding-top: 3em;
  padding-bottom: 1em;
}

footer #footer-detail {
  margin: 0 auto;
  text-align: center;
  font-size: 0.85em;
  width: 100%;
  height: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

footer a:link {
  text-decoration: none;
}

footer #footer-links {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
}

footer #footer-copyright {
  font-size: 0.85em;
  margin-top: 1em;
}
</style>
