import { createStore } from "vuex";
import ApiService from "@/services/ApiService.js";

export default createStore({
  state: {
    token: null,
    id: {},
    my: {},
    cart: [],
    template: {},
    templates: [
      {
        id: 123,
        name: "Paper Carry Bag",
        selectedVariant: 0,
        quantity: 200,
        quantities: [200, 500, 1000, 1500, 2000],
        details: ["Recycled Paper", "Offset printing", "Thread handle"],
        variants: [
          {
            id: 2234,
            color: "yellow",
            image: require("@/assets/products/carry-bag/paper-bag-2.png"),
            stockQuantity: 10,
            quantityPrice: {
              200: 30,
              500: 28,
              1000: 25,
              1500: 23,
              2000: 20,
            },
          },
          {
            id: 2235,
            color: "white",
            image: require("@/assets/products/carry-bag/paper-bag-3.png"),
            stockQuantity: 0,
            quantityPrice: {
              200: 30,
              500: 28,
              1000: 25,
              1500: 23,
              2000: 20,
            },
          },
        ],
      },
      {
        id: 124,
        name: "Paper Carry Bag",
        selectedVariant: 0,
        quantity: 200,
        quantities: [200, 500, 1000, 1500, 2000],
        details: ["Recycled Paper", "Offset printing", "Thread handle"],
        variants: [
          {
            id: 2234,
            color: "yellow",
            image: require("@/assets/products/carry-bag/paper-bag-2.png"),
            stockQuantity: 10,
            quantityPrice: {
              200: 30,
              500: 28,
              1000: 25,
              1500: 23,
              2000: 20,
            },
          },
          {
            id: 2235,
            color: "white",
            image: require("@/assets/products/carry-bag/paper-bag-3.png"),
            stockQuantity: 0,
            quantityPrice: {
              200: 30,
              500: 28,
              1000: 25,
              1500: 23,
              2000: 20,
            },
          },
        ],
      },
    ],
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", JSON.stringify(token));
      ApiService.setToken(token);
    },
    SET_ID(state, id) {
      state.id = id;
      localStorage.setItem("id", JSON.stringify(id));
    },
    SET_MY_DATA(state, myData) {
      state.my = myData;
      localStorage.setItem("my", JSON.stringify(myData));
    },
    CLEAR_MY_DATA() {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("my");
      location.reload();
    },
    ADD_CART_ITEM(state, item) {
      state.cart.push(item);
    },
    REMOVE_CART_ITEM(state, id) {
      state.cart = state.cart.filter((product) => product.id !== id);
    },
    SET_TEMPLATE(state, template) {
      state.template = template;
    },
    SET_TEMPLATES(state, templates) {
      state.templates = templates;
    },
  },
  actions: {
    authorise({ commit }, { token, id }) {
      commit("SET_TOKEN", token);
      commit("SET_ID", id);
    },
    login({ commit }, credentials) {
      return ApiService.login(credentials).then((response) => {
        commit("SET_TOKEN", response.data.value);
        commit("SET_ID", response.data.user.id);
      });
    },
    logout({ commit }) {
      commit("CLEAR_MY_DATA");
    },
    fetchMe({ commit }, id) {
      return ApiService.getUser(id)
        .then((response) => {
          commit("SET_MY_DATA", response.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    addToCart({ commit }, item) {
      /*return ApiService.postTemplate(template)
        .then((response) => {*/
      commit("ADD_CART_ITEM", item);
      /*commit("SET_TEMPLATE", response.data);
        })
        .catch((error) => {
          throw error;
        });*/
    },
    fetchTemplates({ commit }) {
      return ApiService.getTemplates()
        .then((response) => {
          commit("SET_TEMPLATES", response.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    fetchTemplate({ commit, state }, id) {
      const existingTemplate = state.templates.find((template) => {
        if (template.id == id) {
          return template;
        }
      });
      if (existingTemplate) {
        commit("SET_TEMPLATE", existingTemplate);
      } else {
        return ApiService.getTemplate(id)
          .then((response) => {
            commit("SET_TEMPLATE", response.data);
          })
          .catch((error) => {
            throw error;
          });
      }
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.token;
    },
  },
  modules: {},
});
