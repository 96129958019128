<template>
  <h1>Specialized Printing</h1>
  <div class="wrapper">
    <div class="container">
      <div class="card" style="--i: -1">
        <div class="content" style="--j: 1">
          <h3>UV Coating</h3>
          <p>
            Special coating for a high gloss surface finish with gloss, matte,
            raised and sandpaper finish in full page or spot format. Spot UV
            coating for highlighting a particular area to increase the depth of
            colors or to draw attention
          </p>
        </div>
      </div>
      <div class="card" style="--i: 0">
        <div class="content" style="--j: 2">
          <h3>Lamination &amp; Packing</h3>
          <p>
            Lamination protects the richness of colors from fading or
            discolouring and white surfaces from soiling through repeated
            handling and is available in gloss, matt finish to suit the special
            needs of each particular job
          </p>
        </div>
      </div>
      <div class="card" style="--i: 1">
        <div class="content" style="--j: 3">
          <h3>Flexible Packaging</h3>
          <p>
            Aluminium foil, Polyester, BOPP and Poly (HM, LDPE and PP) laminate
            are available
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  /*min-height: 100vh;*/
  /*background: #2e557c;*/
  padding: 1em;
}

.wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none; /* #30bfa5;*/
  clip-path: circle(250px at center);
  transition: 0.5s;
}

.container {
  position: relative;
  width: 1100px;
  height: 400px;
}

.container .card {
  position: absolute;
  top: calc(50% - 200px);
  left: 50%;
  width: 300px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  transform: translateX(-50%) translateY(calc(50px * var(--i))) rotate(40deg)
    skew(-20deg, -10deg) scale(0.6);
  box-shadow: 25px 25px 100px rgba(0, 0, 0, 0.2);
  z-index: calc(-1 * var(--i));
  transition: 0.5s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container:hover .card {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  opacity: 1;
  transform: translateX(calc(-50% + calc(350px * var(--i)))) translateY(-50%)
    rotate(0deg) skew(0deg, 0deg) scale(1);
}

.container .card:nth-child(2) {
  opacity: 0.8;
}

.container .card:nth-child(3) {
  opacity: 0.6;
  z-index: 0;
}

.container .card .content {
  position: relative;
  padding: 25px;
  color: #333;
  opacity: 0;
  transition: 0.5s;
  transform: translateY(50px);
}

.container:hover .card .content {
  opacity: 1;
  transition-delay: calc(0.2s * var(--j));
  transform: translateY(0px);
}

.container .card .content p {
  font-size: 1em;
  font-weight: 300;
  color: #777;
}

@media (max-width: 600px) {
  .wrapper::before {
    clip-path: circle(0px at center);
  }

  .container {
    width: 100%;
    height: initial;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .container .card {
    position: relative;
    top: initial;
    left: initial;
    transform: initial;
    margin: 20px;
    opacity: 1 !important;
  }

  .container:hover,
  .container:hover .card {
    position: relative;
    top: initial;
    left: initial;
    transform: initial;
  }

  .container .card .content {
    transform: initial;
    opacity: 1;
  }
}
</style>
