<template>
  <div class="product-display">
    <div class="product-container">
      <div class="product-image">
        <a rel="ar" :href="`${publicPath}img/3d/paper-bag-3d.usdz`">
          <img :src="image" />
        </a>
      </div>

      <div class="product-info">
        <h1>{{ template.name }}</h1>
        <h3>Choose Colour</h3>
        <div class="horizontal-flex">
          <div
            class="color-circle"
            v-for="(variant, index) in template.variants"
            :key="variant.id"
            :style="{ backgroundColor: variant.color }"
            @mouseover="updateProduct(index)"
          ></div>
        </div>
        <p v-if="inStock">In Stock</p>
        <p v-else>Out of Stock</p>

        <label>Quantity: </label>
        <select v-model="template.quantity">
          <option
            v-for="option in template.quantities"
            :key="option"
            :value="option"
            :selected="option === template.quantity"
          >
            {{ option }}
          </option>
        </select>
        <br />

        <p v-if="inStock">Unit Price: {{ unitPrice }}</p>
        <p>Shipping: {{ shipping }}</p>

        <ul>
          <li v-for="(detail, index) in template.details" :key="index">
            {{ detail }}
          </li>
        </ul>

        <div>
          <form ref="myform"></form>
        </div>

        <button
          type="button"
          :class="{ disabledButton: !inStock }"
          @click="addToCart(this.id)"
        >
          Add to cart
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  created() {
    this.$store.dispatch("fetchTemplate", this.id).catch((error) => {
      if (error.response && error.response.status == 400) {
        this.$router.push({
          name: "400Error",
          params: { resource: "template" },
        });
      } else if (error.response && error.response.status == 401) {
        this.$router.push({
          name: "LoginGoogle",
        });
      } else if (error.response && error.response.status == 403) {
        this.$router.push({
          name: "403Error",
          params: { error: error.response.data.reason },
        });
      } else if (error.response && error.response.status == 404) {
        this.$router.push({
          name: "404Resource",
          params: { resource: "template" },
        });
      } else if (error) {
        this.$router.push({
          name: "ErrorDisplay",
          params: { error: error },
        });
      } else {
        this.$router.push({
          name: "NetworkError",
          params: { error: error },
        });
      }
    });
  },
  mounted() {
    let foo = document.createElement("script");
    foo.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    foo.setAttribute("data-payment_button_id", "pl_I3sJFbddY7d645");
    this.$refs.myform.appendChild(foo);
  },
  computed: {
    template() {
      return this.$store.state.template;
    },
    image() {
      return this.$store.state.template.variants[
        this.$store.state.template.selectedVariant
      ].image;
    },
    inStock() {
      return this.$store.state.template.variants[
        this.$store.state.template.selectedVariant
      ].stockQuantity;
    },
    unitPrice() {
      return (
        "₹" +
        this.$store.state.template.variants[
          this.$store.state.template.selectedVariant
        ].quantityPrice[this.$store.state.template.quantity] +
        " per piece"
      );
    },
    shipping() {
      /*if (this.$store.state.template.quantity > 1000) {
        return "Free";
      }*/
      return "₹" + 299;
    },
  },
  methods: {
    addToCart(id) {
      this.$store.dispatch("addToCart", id);
    },
    updateProduct(index) {
      this.$store.state.template.selectedVariant = index;
    },
  },
};
</script>

<style scoped>
.product-display {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-image,
.product-info {
  width: 50%;
  text-align: left;
}

img {
  border: 2px solid #d8d8d8;
  width: 70%;
  margin: 40px;
  padding: 15px;
  box-shadow: 2px 15px -12px rgba(0, 0, 0, 0.57);
}

@media only screen and (max-width: 600px) {
  .product-image,
  .product-info {
    margin-left: 10px;
    width: 100%;
  }
}
</style>
