<template>
  <div class="list-wrapper">
    <h1>This action cannot be processed</h1>
    <h3>{{ error }}</h3>

    <p>Try again? <a href="#" @click="$router.go(-1)"> Go back </a> to try</p>

    <router-link :to="{ name: 'Home' }">Back to the home page</router-link>
  </div>
</template>

<script>
export default {
  props: ["error"],
};
</script>
