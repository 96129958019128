<template>
  <router-link
    class="template-link"
    :to="{ name: 'Template', params: { id: template.id } }"
  >
    <!-- div class="template-card">
      <h4>{{ template.name }}</h4>
      <span class="capiltalize">In {{ template.city }}</span>
    </div -->

    <div class="container">
      <div class="card">
        <div class="image-box">
          <img :src="image" />
        </div>
        <div class="content-box">
          <h2>{{ template.name }}</h2>
          <div class="size">
            <h3>Size :</h3>
            <span>S</span>
            <span>M</span>
            <span>L</span>
            <span>XL</span>
          </div>
          <div class="color">
            <h3>Colour :</h3>
            <span></span>
            <span></span>
            <span></span>
            <!-- span></span-->
          </div>
          <!-- a href="#">Buy Now</a -->
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return this.template.variants[this.template.selectedVariant].image;
    },
  },
};
</script>

<style scoped>
.template-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  border-radius: 6px;
  margin: 1em;

  background-color: #fff;
}
.template-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
.template-link {
  color: #2c3e50;
  text-decoration: none;
}

.capiltalize {
  text-transform: capitalize;
}

img {
  margin: 0;
  width: 250px;
  height: 300px;
  display: inline-block;
  object-fit: cover;
}

.container {
  margin: 1em;
}

.container .card {
  position: relative;
  width: 320px;
  height: 450px;
  background: #ddd;
  border-radius: 20px;
  overflow: hidden;
}

.container .card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}

.container .card:hover:before {
  clip-path: circle(300px at 80% -20%);
}

.container .card::after {
  content: "Bag";
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 10em;
  font-weight: 800;
  font-style: italic;
  color: rgba(0, 0, 0, 0.04);
}

.container .card .image-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.container .card:hover .image-box {
  top: 0%;
  transform: translateY(0%);
}

.container .card .image-box img {
  position: absolute;
  top: 40%; /*  vary for title height above image */
  left: 50%;
  transform: translate(-50%, -50%) /*rotate(-25deg)*/;
  width: 270px;
}

.container .card .content-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}

.container .card:hover .content-box {
  height: 210px;
}

.container .card .content-box h2 {
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
}

.container .card .content-box .size,
.container .card .content-box .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.container .card:hover .content-box .size {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:hover .content-box .color {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container .card .content-box .size h3,
.container .card .content-box .color h3 {
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  margin-right: 10px;
}

.container .card .content-box .size span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 0.8em;
  display: inline-block;
  color: #eee;
  background: #000;
  margin: 0 5px;
  transition: 0.5s;
  border-radius: 4px;
  cursor: pointer;
}

.container .card .content-box .size span:hover {
  background: chartreuse;
}

.container .card .content-box .color span {
  width: 20px;
  height: 20px;
  background: #ff0;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.container .card .content-box .color span:nth-child(2) {
  background: #8b4513;
}

.container .card .content-box .color span:nth-child(3) {
  background: #fff;
}

.container .card .content-box .color span:nth-child(4) {
  background: #ff0;
}

.container .card .content-box a {
  display: inline-block;
  padding: 10px 20px;
  /*background: #000;*/
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  opacity: 0;
  transform: translate(50px);
  transition: 0.5s;
}

.container .card:hover .content-box a {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
}
</style>
