<template>
  <div class="list-wrapper">
    <div v-if="my">
      <h1>{{ my.name }}</h1>
      <b>{{ my.username }}</b>

      <div class="flex-container">
        <button type="button" class="button info" @click="loadMyCustomers(id)">
          My Customers
        </button>

        <button type="button" class="button info" @click="loadMyJobs(id)">
          My Jobs
        </button>
      </div>

      <h3>
        <span class="userType">{{ my.userType }}</span> user
      </h3>
    </div>
    <button type="button" class="logoutButton" @click="logout">Logout</button>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("fetchMe", this.$store.state.id).catch((error) => {
      if (error.response && error.response.status == 400) {
        this.$router.push({
          name: "400Error",
          params: { resource: "my" },
        });
      } else if (error.response && error.response.status == 401) {
        this.$router.push({
          name: "LoginGoogle",
        });
      } else if (error.response && error.response.status == 404) {
        this.$router.push({
          name: "404Resource",
          params: { resource: "my" },
        });
      } else if (error) {
        this.$router.push({
          name: "ErrorDisplay",
          params: { error: error },
        });
      } else {
        this.$router.push({
          name: "NetworkError",
          params: { error: error },
        });
      }
    });
  },
  computed: {
    my() {
      return this.$store.state.my;
    },
    id() {
      return this.$store.state.id;
    },
  },
  methods: {
    loadMyCustomers(id) {
      this.$router.push({
        name: "MyCustomers",
        params: { id },
      });
    },
    loadMyJobs(id) {
      this.$router.push({
        name: "MyJobs",
        params: { id },
      });
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
.button {
  margin: 1em;
  justify-content: center;
  align-items: center;
}

.danger {
  background-color: red;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1em;
}

.userType {
  text-transform: capitalize;
}

@media (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
